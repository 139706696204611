import React, { useEffect, useState } from 'react';
import './prediction.css';

const Prediction = () => {
  const [moonshotData, setMoonshotData] = useState([]);
  const [allTokensData, setAllTokensData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'overallRating', direction: 'descending' });

  // Fetch moonshot documents
  const fetchMoonshotData = async () => {
    try {
      const moonshotResponse = await fetch(process.env.REACT_APP_HOME_MOONSHOTS_API);
      const moonshotResult = await moonshotResponse.json();
      // Sort moonshot data by rating in descending order
      const sortedMoonshotData = moonshotResult.result.sort((a, b) => {
        return calculateChance3xTimes2(b) - calculateChance3xTimes2(a); // Sort by rating, highest to lowest
      });
      
      setMoonshotData(sortedMoonshotData);
    } catch (error) {
      setError('Error fetching moonshot data');
    }
  };
  // Fetch allTokens documents
  const fetchAllTokensData = async () => {
    try {
      const allTokensResponse = await fetch(process.env.REACT_APP_HOME_ALLTOKENS_API);
      const allTokensResult = await allTokensResponse.json();
      setAllTokensData(allTokensResult.result);
    } catch (error) {
      setError('Error fetching allTokens data');
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      await fetchMoonshotData();
      await fetchAllTokensData();
      setLoading(false);
    };

    fetchData();
  }, []);

  // Helper function to count matching labels between moonshot and allTokens
  const countLabelMatches = (label) => {
    return allTokensData.filter((token) => token.label === label).length;
  };

  // Helper function to sum mcClosing values for matching labels
  const sumMcClosingForLabel = (label) => {
    const matchingTokens = allTokensData.filter((token) => token.label === label);
    return matchingTokens.reduce((sum, token) => sum + (parseFloat(token.mcClosing) || 0), 0);
  };

  // Calculate the average mcClosing for matching labels
  const averageMcClosingForLabel = (label) => {
    const matchingTokens = allTokensData.filter((token) => token.label === label);
    const count = matchingTokens.length;
    const sum = matchingTokens.reduce((sum, token) => sum + (parseFloat(token.mcClosing) || 0), 0);
    return count > 0 ? sum / count : 0; // Avoid division by zero
  };

    // Calculate Sell Rating 1
    const calculateSellRating1 = (token) => {
        let rating = 0;
        const avgMcClosing = averageMcClosingForLabel(token.label);
        const mcNow = parseFloat(token.mcClosing);
        const xGained = (mcNow / parseFloat(token.mcOpen) - 1).toFixed(2);
        const avgXGained = averageXGainedForLabel(token.label);
        const sell1Value = avgXGained * parseFloat(token.mcOpen);
        const xGainedSell1 = sell1Value / parseFloat(token.mcClosing) - 1
    
        // Apply conditions
        if (mcNow < avgMcClosing) rating += 37; // Condition 1
        if (parseFloat(xGainedSell1) >= 3) rating += 32; // Condition 2
        if (xGained > 0 && xGained < 3) rating += 22; // Condition 3
        if (mcNow < 500000) rating += 9; // Condition 4
    
        return rating; // Return total rating
      };

          // Calculate Sell Rating 1
    const calculateSellRating2 = (token) => {
        let rating2 = 0;
        const avgMcClosing = averageMcClosingForLabel(token.label);
        const mcNow = parseFloat(token.mcClosing);
        const xGained = (mcNow / parseFloat(token.mcOpen) - 1).toFixed(2);
        const xGainedSell2 = highestMcClosingForLabel(token.label) / parseFloat(token.mcClosing) - 1;
        const avgXGained = averageXGainedForLabel(token.label);
        const sell1Value = avgXGained * parseFloat(token.mcOpen);
        const xGainedSell1 = sell1Value / parseFloat(token.mcClosing) - 1
    
        // Apply conditions
        if (mcNow < avgMcClosing) rating2 += 14; // Condition 1
        if (parseFloat(xGainedSell1) >= 3) rating2 += 29; 
        if (parseFloat(xGainedSell2) >= 10) rating2 += 32; // Condition 2
        if (xGained > 0 && xGained < 3) rating2 += 16; // Condition 3
        if (mcNow < 500000) rating2 += 9; // Condition 4
    
        return rating2; // Return total rating
      };


  // Calculate the lowest mcClosing value for matching labels
  const lowestMcClosingForLabel = (label) => {
    const matchingTokens = allTokensData.filter((token) => token.label === label);
    return matchingTokens.length > 0
      ? Math.min(...matchingTokens.map((token) => parseFloat(token.mcClosing) || 0))
      : 0;
  };

  // Calculate the highest mcClosing value for matching labels
  const highestMcClosingForLabel = (label) => {
    const matchingTokens = allTokensData.filter((token) => token.label === label);
    return matchingTokens.length > 0
      ? Math.max(...matchingTokens.map((token) => parseFloat(token.mcClosing) || 0))
      : 0;
  };

  // Calculate the lowest xGained value for matching labels
  const lowestXGainedForLabel = (label) => {
    const matchingTokens = allTokensData.filter((token) => token.label === label);
    return matchingTokens.length > 0
      ? Math.min(...matchingTokens.map((token) => parseFloat(token.xGained) || 0))
      : 0;
  };

  // Calculate the highest xGained value for matching labels
  const highestXGainedForLabel = (label) => {
    const matchingTokens = allTokensData.filter((token) => token.label === label);
    return matchingTokens.length > 0
      ? Math.max(...matchingTokens.map((token) => parseFloat(token.xGained) || 0))
      : 0;
  };

  // Count documents where xGained > 3 for a label
  const countXGainedGreaterThan3ForLabel = (label) => {
    return allTokensData.filter((token) => token.label === label && parseFloat(token.xGained) > 3).length;
  };

  // Count documents where xGained >= 10 for a label
  const countXGainedGreaterThanOrEqualTo10ForLabel = (label) => {
    return allTokensData.filter((token) => token.label === label && parseFloat(token.xGained) >= 10).length;
  };

    // Calculate chance of achieving 3x
    const chanceOf3x = (label) => {
        const countMatches = countLabelMatches(label);
        const countGreaterThan3 = countXGainedGreaterThan3ForLabel(label);
        return countMatches > 0 ? (countGreaterThan3 / countMatches) * 100 : 0; // Return percentage
      };
    
      // Calculate chance of achieving 10x
      const chanceOf10x = (label) => {
        const countMatches = countLabelMatches(label);
        const countGreaterThanOrEqualTo10 = countXGainedGreaterThanOrEqualTo10ForLabel(label);
        return countMatches > 0 ? (countGreaterThanOrEqualTo10 / countMatches) * 100 : 0; // Return percentage
      };

        // Sum xGained values for matching labels
  const sumXGainedForLabel = (label) => {
    const matchingTokens = allTokensData.filter((token) => token.label === label);
    return matchingTokens.reduce((sum, token) => sum + (parseFloat(token.xGained) || 0), 0);
  };

// Calculate the average xGained for matching labels
const averageXGainedForLabel = (label) => {
    const matchingTokens = allTokensData.filter((token) => token.label === label);
    const count = matchingTokens.length;
    const sum = matchingTokens.reduce((sum, token) => sum + (parseFloat(token.xGained) || 0), 0);
    return count > 0 ? sum / count : 0; // Avoid division by zero
};

// Function to determine the highest xGained value for any label
const getHighestXGainedValue = () => {
    const labels = [...new Set(allTokensData.map(token => token.label))];
    let highestValue = -Infinity;
    
    labels.forEach(label => {
        const currentHighest = highestXGainedForLabel(label);
        if (currentHighest > highestValue) {
            highestValue = currentHighest;
        }
    });

    return highestValue;
};

// Update calculateChance3xTimes2 to include the new condition
const calculateChance3xTimes2 = (token) => {
    const avgMcClosing = averageMcClosingForLabel(token.label);
    const highestXGainedValue = getHighestXGainedValue(); // Get the highest xGained value
    
    let chance = 0;
    if (parseFloat(token.mcOpen) > avgMcClosing / 2) {
        chance = chanceOf3x(token.label) * 2;
    }

    // Check if this token has the highest highestXGainedForLabel value
    if (highestXGainedForLabel(token.label) === highestXGainedValue) {
        chance += 14.28; // Add 14.28 if the condition is met
    }

    return chance;
};

const sortedMoonshotData = [...moonshotData].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];
    if (aValue < bValue) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

    

  if (loading) {
    return <p>Loading predictions...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="prediction">
      <h3>Predictions</h3>
      {moonshotData.length === 0 ? (
        <p>No predictions available.</p>
      ) : (
        <table>
          <thead>
            <tr>
            <th onClick={() => requestSort('tokenSymbol')}>Token Symbol</th>
              <th onClick={() => requestSort('label')}>Label</th>
              <th onClick={() => requestSort('contractAddress')}>Contract Address</th>
              <th onClick={() => requestSort('mcOpen')}>Market Cap (Open)</th>
              <th onClick={() => requestSort('mcClosing')}>Market Cap (Now)</th>
              <th onClick={() => requestSort('xGained')}>X Gained</th>
              <th onClick={() => requestSort('sell1MC')}>Sell 1 MC</th>
              <th onClick={() => requestSort('sell1X')}>Sell 1 X</th>
              <th onClick={() => requestSort('sell1Rating')}>Sell 1 Rating</th>
              <th onClick={() => requestSort('sell2MC')}>Sell 2 MC</th>
              <th onClick={() => requestSort('sell2X')}>Sell 2 X</th>
              <th onClick={() => requestSort('sell2Rating')}>Sell 2 Rating</th>
              <th onClick={() => requestSort('overallRating')}>Overall Rating</th>
             {/* <th>Tests</th>*/}
             <th onClick={() => requestSort('averageMcClosing')}>Average mcClosing</th>
             {/*  <th>Lowest xGained</th>*/}
            {/* <th>Average xGained</th>*/}
           {/*   <th>Highest xGained</th>*/}
            {/*  <th>Chance 3x</th> */}
            {/*  <th>Chance 10x</th> */}
            </tr>
          </thead>
          <tbody>
          {sortedMoonshotData.map((token, index) => {
              const sell1Rating = calculateSellRating1(token);
              const sell2Rating = calculateSellRating2(token);
              const avgXGained = averageXGainedForLabel(token.label);
              const sell1Value = avgXGained * parseFloat(token.mcOpen);
              return (
              <tr key={index}>
                <td>{token.tokenSymbol}</td>
                <td>{token.label}</td>
                <td>{token.contractAddress.slice(0, 4)}...{token.contractAddress.slice(-3)}</td>
                <td>
                  {parseFloat(token.mcOpen).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </td>
                <td>
                  {parseFloat(token.mcClosing).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </td>
                <td>{(parseFloat(token.mcClosing) / parseFloat(token.mcOpen) - 1).toFixed(2)}</td>
                <td>
                    {sell1Value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} {/* Display Sell 1 value */}
                </td>
                <td>{(sell1Value / parseFloat(token.mcClosing) - 1).toFixed(2)}</td>
                <td>{sell1Rating}</td>

                <td>{highestMcClosingForLabel(token.label).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                <td>{(highestMcClosingForLabel(token.label) / parseFloat(token.mcClosing) - 1).toFixed(2)}</td>
                <td>{sell2Rating}</td>

                <td>{(sell1Rating + sell2Rating) / 2}%</td>
              {/*  <td>{countLabelMatches(token.label)}</td>*/}
                <td>
                  {averageMcClosingForLabel(token.label).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </td>
               {/*  <td>
                  {lowestXGainedForLabel(token.label).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2 })}
                </td>*/}
               {/*    <td>{averageXGainedForLabel(token.label).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2 })}</td>*/}
               {/*    <td>
                  {highestXGainedForLabel(token.label).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2 })}*/}
              {/*     </td>
               {/* <td>{chanceOf3x(token.label).toFixed(2)}%</td>*/}
              {/*  <td>{chanceOf10x(token.label).toFixed(2)}%</td>*/}
              </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Prediction;
