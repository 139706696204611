import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './allTokens.css';

const AllHistory = () => {
  const [totalTokens, setTotalTokens] = useState(0);
  const [countXGained3, setCountXGained3] = useState(0);
  const [countXGained10, setCountXGained10] = useState(0);
  const [countXGainedLessThan3, setCountXGainedLessThan3] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_HISTORY_API);
        const tokens = response.data.result;
        
        setTotalTokens(tokens.length);

        let count3 = 0;
        let count10 = 0;
        let countLessThan3 = 0;

        tokens.forEach(token => {
          const xGained = token.xGained || 0;
          if (xGained >= 9) {
            count10++;
          }
          if (xGained >= 2) {
            count3++;
          } else {
            countLessThan3++;
          }
        });

        setCountXGained3(count3);
        setCountXGained10(count10);
        setCountXGainedLessThan3(countLessThan3);
        
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTokens();
  }, []);

  const handleTokensDetectedClick = () => {
    navigate('/all-token-data'); // Navigate to AllTokenData
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching tokens: {error.message}</div>;
  }

  return (
    <div className='allTokensMain'>
     <div className='squareHolder'>
      <h2>Past 30 Days</h2>
      </div>
      <div className='squareHolder'>
        <div className='square' onClick={handleTokensDetectedClick} style={{ cursor: 'pointer' }}>
          <div className='squareDataTitle'>Tokens Detected</div>
          <div className='squareData'>{totalTokens}</div>
        </div>
        <div className='square'>
          <div className='squareDataTitle'>3X +</div>
          <div className='squareData'>{countXGained3}</div>
        </div>
        <div className='square'>
          <div className='squareDataTitle'>10X +</div>
          <div className='squareData'>{countXGained10}</div>
        </div>
        <div className='square'>
          <div className='squareDataTitle'>0 Gains</div>
          <div className='squareData'>{countXGainedLessThan3}</div>
        </div>
      </div>
    </div>
  );
};

export default AllHistory;
