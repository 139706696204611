import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './authContext'; // Import AuthContext
import Logo from './logo.jpg';
import './App.css';
import { FaSignOutAlt, FaUser } from 'react-icons/fa';

function Header() {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext); // Use context
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const userId = localStorage.getItem('userId');

  // Log the userId
  console.log('Logged in userId:', userId);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 50);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/');
  };

  return (
    <header className={`header ${isScrolled ? 'header-scrolled' : ''}`}>
      <div className="header-content">
        <div className="header-left">
          <img src={Logo} alt="Logo" className="logo" />
          <h1>Trend Scout</h1>
        </div>
        <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>

        <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
          {isAuthenticated ? (
            <>
              <div className="submenu-title"><Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link></div>
              <div className="submenu-container">
                <span className="submenu-title" onClick={toggleSubmenu}>Current Tokens</span>
                {submenuOpen && (
                  <div className="submenu">
                    <Link to="/alltokens" onClick={() => { setIsMenuOpen(false); setSubmenuOpen(false); }}>Current Tokens</Link>
                    {userId === 'pziPDtOgJiX06oGuJxNwxE' && (
                      <>
                    <Link to="/all-token-data" onClick={() => { setIsMenuOpen(false); setSubmenuOpen(false); }}>Data</Link>
                    </>
                    )}
                  </div>
                )}
              </div>

              <div className="submenu-container">
                <span className="submenu-title" onClick={toggleSubmenu}>History</span>
                {submenuOpen && (
                  <div className="submenu">
                    <Link to="/historical-tokens" onClick={() => setIsMenuOpen(false)}>All Tokens</Link>
                    {userId === 'pziPDtOgJiX06oGuJxNwxE' && (
                      <>
                    <Link to="/historical-token-data" onClick={() => { setIsMenuOpen(false); setSubmenuOpen(false); }}>All Data</Link>
                    </>
                    )}
                    {(userId === 'pziPDtOgJiX06oGuJxNwxE' || userId === '84e2208f-e4c5-45c4-a68b-97e429b20206') && (
                      <>
                        <Link to="/stored-tokens" onClick={() => { setIsMenuOpen(false); setSubmenuOpen(false); }}>Stored Tokens</Link>
                        </>
                    )}
                     {userId === 'pziPDtOgJiX06oGuJxNwxE' && (
                      <>
                        <Link to="/stored-token-data" onClick={() => { setIsMenuOpen(false); setSubmenuOpen(false); }}>Stored Data</Link>
                      </>
                    )}
                  </div>
                )}
              </div>
              {userId !== 'pziPDtOgJiX06oGuJxNwxE' && (
                      <>
              <div className="submenu-title"><Link to="/performance-sim" onClick={() => setIsMenuOpen(false)}>Simulation</Link></div>
              </>
                    )}
              {userId === 'pziPDtOgJiX06oGuJxNwxE' && (
                      <>             
              <div className="submenu-title"><Link to="/performance-sim-all" onClick={() => setIsMenuOpen(false)}>Simulation</Link></div>
              </>
                    )}
              <div className="submenu-title"><Link to="/prediction" onClick={() => setIsMenuOpen(false)}>Prediction</Link></div>
              <div className="logout-button" onClick={handleLogout}>
                <FaSignOutAlt size={24} color="white" />
                <span className="submenu-title">Logout</span>
              </div>
            </>
          ) : (
            <div className="login-button">
              <Link to="/login">
                <FaUser className='fauser' size={20} color="white" />
                <span className="submenu-title">Login / Signup</span>
              </Link>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;
