import React, { useEffect, useState } from 'react';
import AllTokens from './allTokens';
import Home from './home';
import Header from '../header';
import Trending from './trending';

const HomeMain = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1400);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1400);
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Header/>
      {isWideScreen && <Trending />}
      <AllTokens />
      <Home />
    </div>
  );
};

export default HomeMain;

