import React, { createContext, useState, useEffect } from 'react';

// Create AuthContext
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  const [userId, setUserId] = useState(() => {
    return localStorage.getItem('userId') || null; // Get userId from localStorage
  });

  // Update localStorage whenever `isAuthenticated` changes
  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  // Update localStorage whenever `userId` changes
  useEffect(() => {
    localStorage.setItem('userId', userId); // Store userId in localStorage
  }, [userId]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, userId, setUserId }}>
      {children}
    </AuthContext.Provider>
  );
};
