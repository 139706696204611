import React, { useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from './authContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, userId } = useContext(AuthContext); // Get userId from context
  const [isLoading, setIsLoading] = useState(true);
  const [hasValidSubscription, setHasValidSubscription] = useState(false);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:5000' : 'https://www.trendscout.club';

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        if (!userId) {
          throw new Error('User not logged in.');
        }

        const response = await axios.post(`${baseUrl}/api/check-subscription`, { userId }); // Send userId to the backend

        if (response.status === 200) {
          setHasValidSubscription(true);
        } else {
          setHasValidSubscription(false);
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
        setHasValidSubscription(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (isAuthenticated) {
      checkSubscriptionStatus();
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated, userId]); // Add userId to the dependency array

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated || !hasValidSubscription) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
