import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './allTokensData.css'; // Importing CSS for styling
import * as XLSX from 'xlsx'; // Import xlsx package for exporting to Excel

const AllTokenData = () => {
  const [tokens, setTokens] = useState([]);
  const [filter, setFilter] = useState({});
  const [visibleColumns, setVisibleColumns] = useState({
    tokenName: true,
    tokenSymbol: true,
    contractAddress: true,
    honeyPot: true,
    openSource: true,
    meta: true,
    metaDetectedTime: true,
    metaDetectedMc: true,
    risk: true,
    mcOpen: true,
    mcClosing: true,
    xGained: true,
    label: true,
    buysFiveMin: true,
    buysOneHour: true,
    buysSixHour: true,
    buysTwoFourHour: true,
    sellsFiveMin: true,
    sellsOneHour: true,
    sellsSixHour: true,
    sellsTwoFourHour: true,
    volumeFiveMin: true,
    volumeOneHour: true,
    volumeSixHour: true,
    volumeTwoFourhr: true,
    changeFiveMin: true,
    changeOneHour: true,
    changeSixHour: true,
    changeTwoFourHr: true,
    usdLp: true,
    tokensLp: true,
    ethLp: true,
    detectedHour: true,
    daysAgo: true,
    createdAt: true,
    createdHour: true, 
  });
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_HOME_ALLTOKENS_API);
      setTokens(response.data.result);
    } catch (error) {
      console.error('Error fetching token data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const exportToExcel = () => {
    const exportData = tokens.map((token) => {
      let tokenData = {};
      Object.keys(visibleColumns).forEach((column) => {
        if (visibleColumns[column]) {
          // Handle createdHour separately by extracting the time from createdAt in 24-hour format
          if (column === 'createdHour') {
            tokenData[column] = token.createdAt
              ? new Date(token.createdAt).toLocaleTimeString([], { hour: '2-digit', hour12: false }) // 24-hour format
              : '';
          } else if (column === 'createdAt') {
            // Format createdAt as a readable date
            tokenData[column] = token.createdAt
              ? new Date(token.createdAt).toLocaleDateString()
              : '';
          } else {
            tokenData[column] = token[column] || '';
          }
        }
      });
  
      // Add the day of the week
      tokenData['dayOfWeek'] = token.createdAt
        ? new Date(token.createdAt).toLocaleDateString('en-US', { weekday: 'long' })
        : '';
  
      return tokenData;
    });
  
    // Create a worksheet and workbook
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'CurrentData');
  
    // Export the workbook
    XLSX.writeFile(wb, 'current_data.xlsx');
  };
  
  

  // Function to handle row click
  const handleRowClick = (contractAddress) => {
    const url = `https://www.dextools.io/app/en/ether/pair-explorer/${contractAddress}`;
    window.open(url, '_blank'); // Open in a new tab or window
  };

  // Function to toggle column visibility
  const toggleColumnVisibility = (column) => {
    setVisibleColumns({
      ...visibleColumns,
      [column]: !visibleColumns[column],
    });
  };

  // Function to handle sorting
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Sorting function
  const sortedTokens = [...tokens].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
    } else if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortConfig.direction === 'ascending'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }
    return 0;
  });

  // Filter tokens based on filter state
  const filteredTokens = sortedTokens.filter((token) => {
    return Object.keys(filter).every((column) => {
      if (!filter[column]) return true;
      const value = token[column] ? token[column].toString().toLowerCase() : '';
      return value.includes(filter[column]);
    });
  });

  // Get hidden columns
  const hiddenColumns = Object.keys(visibleColumns).filter(column => !visibleColumns[column]);

  return (
    <div className='allTokenDataMain'>
      <h2>Current Token Data</h2>

      {/* Button to export data */}
      <button onClick={exportToExcel} style={{ marginBottom: '10px' }}>Export to Excel</button>

      {/* Display hidden column names */}
      {hiddenColumns.length > 0 && (
        <div className="hidden-columns">
          <h4>Hidden Columns:</h4>
          {hiddenColumns.map((column) => (
            <span 
              key={column} 
              className="hidden-column" 
              onClick={() => toggleColumnVisibility(column)} 
              style={{ cursor: 'pointer', marginRight: '10px', color: 'blue' }}>
              {column}
            </span>
          ))}
        </div>
      )}

      <table className="token-table">
        <thead>
          <tr>
            {Object.keys(visibleColumns).map((column) => visibleColumns[column] && (
              <th key={column} onClick={() => requestSort(column)}>
                <input
                  type="checkbox"
                  checked={visibleColumns[column]}
                  onChange={() => toggleColumnVisibility(column)}
                  style={{ marginBottom: '5px' }} // Add some space below the checkbox
                />
                {column}
                {sortConfig.key === column ? (sortConfig.direction === 'ascending' ? ' ↑' : ' ↓') : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredTokens.map((token, index) => (
            <tr key={index} onClick={() => handleRowClick(token.contractAddress)}>
              {visibleColumns.tokenName && <td>{token.tokenName}</td>}
              {visibleColumns.tokenSymbol && <td>{token.tokenSymbol}</td>}
              {visibleColumns.contractAddress && <td>{token.contractAddress.slice(0, 6)}...{token.contractAddress.slice(-4)}</td>}
              {visibleColumns.honeyPot && <td>{token.honeyPot ? 'Yes' : 'No'}</td>}
              {visibleColumns.openSource && <td>{token.openSource ? 'Yes' : 'No'}</td>}
              {visibleColumns.meta && <td>{token.meta}</td>}
              {visibleColumns.metaDetectedTime && <td>{token.metaDetectedTime}</td>}
              {visibleColumns.metaDetectedMc && <td>{token.metaDetectedMc}</td>}
              {visibleColumns.risk && <td>{token.risk}</td>}
              {visibleColumns.mcOpen && <td>{token.mcOpen}</td>}
              {visibleColumns.mcClosing && <td>{token.mcClosing}</td>}
              {visibleColumns.xGained && <td>{token.xGained?.toFixed(2)}</td>}
              {visibleColumns.label && <td>{token.label}</td>}
              {visibleColumns.buysFiveMin && <td>{token.buysFiveMin}</td>}
              {visibleColumns.buysOneHour && <td>{token.buysOneHour}</td>}
              {visibleColumns.buysSixHour && <td>{token.buysSixHour}</td>}
              {visibleColumns.buysTwoFourHour && <td>{token.buysTwoFourHour}</td>}
              {visibleColumns.sellsFiveMin && <td>{token.sellsFiveMin}</td>}
              {visibleColumns.sellsOneHour && <td>{token.sellsOneHour}</td>}
              {visibleColumns.sellsSixHour && <td>{token.sellsSixHour}</td>}
              {visibleColumns.sellsTwoFourHour && <td>{token.sellsTwoFourHour}</td>}
              {visibleColumns.volumeFiveMin && <td>{token.volumeFiveMin}</td>}
              {visibleColumns.volumeOneHour && <td>{token.volumeOneHour}</td>}
              {visibleColumns.volumeSixHour && <td>{token.volumeSixHour}</td>}
              {visibleColumns.volumeTwoFourhr && <td>{token.volumeTwoFourhr}</td>}
              {visibleColumns.changeFiveMin && <td>{token.changeFiveMin}</td>}
              {visibleColumns.changeOneHour && <td>{token.changeOneHour}</td>}
              {visibleColumns.changeSixHour && <td>{token.changeSixHour}</td>}
              {visibleColumns.changeTwoFourHr && <td>{token.changeTwoFourHr}</td>}
              {visibleColumns.usdLp && <td>{token.usdLp}</td>}
              {visibleColumns.tokensLp && <td>{token.tokensLp}</td>}
              {visibleColumns.ethLp && <td>{token.ethLp}</td>}
              {visibleColumns.detectedHour && <td>{token.detectedHour}</td>}
              {visibleColumns.daysAgo && <td>{token.daysAgo}</td>}
              {visibleColumns.createdAt && <td>{new Date(token.createdAt).toLocaleDateString()}</td>}
              {visibleColumns.createdHour && <td>{new Date(token.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>} 
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllTokenData;
