import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faStar, faFire, faEye, faFilter, faTrash } from '@fortawesome/free-solid-svg-icons';
import AnimatedNumber from './animatedNumber';
import axios from 'axios';
import './home.css';
import './fiveDayTokens.css';
import AllHistory from './allHistory';

function HistoryTokens() {
  const userId = localStorage.getItem('userId');
  const [moonshots, setMoonshots] = useState([]);
  const previousMoonshotsRef = useRef([]);
  const [sortField, setSortField] = useState('tokenName'); // Default sort field
  const [sortOrder, setSortOrder] = useState('asc'); // Default sort order

  // State variables for existing filters
  const [labelFilter, setLabelFilter] = useState('');
  const [mcnowFilter, setMcnowFilter] = useState({ min: '', max: '' });
  const [mcopenFilter, setMcopenFilter] = useState({ min: '', max: '' });
  const [priceChangeFilter, setPriceChangeFilter] = useState({ min: '', max: '' });
  const [gainsFilter, setGainsFilter] = useState({ min: '', max: '' });
  const [maxGainsFilter, setMaxGainsFilter] = useState({ min: '', max: '' });

  // **New Filter State Variables**
  const [gainsThresholdFilter, setGainsThresholdFilter] = useState(false);
  const [mcNowLessThanOrEqualFilter, setMcNowLessThanOrEqualFilter] = useState(false);
  const [gains2ThresholdFilter, setGains2ThresholdFilter] = useState(false);
  const [mcNowGreaterThanOrEqualFilter, setMcNowGreaterThanOrEqualFilter] = useState(false);

  const [showFilters, setShowFilters] = useState(false);

  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:5000' : 'https://www.trendscout.club';

  useEffect(() => {
    // Fetch initial data
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_HISTORY_API);
        const data = await response.json();
        setMoonshots(data.result || []);
        previousMoonshotsRef.current = data.result || []; // Initialize the ref with initial data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Setup interval to fetch data every minute
    const intervalId = setInterval(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_HISTORY_API);
          const data = await response.json();
          const updatedMoonshots = data.result || [];
          previousMoonshotsRef.current = moonshots; // Save previous state before updating
          setMoonshots(updatedMoonshots); // Update state with new moonshots
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, 3600000); // 3600000 milliseconds = 1 hour

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [moonshots]);

  // Update previousMoonshotsRef whenever moonshots change
  useEffect(() => {
    previousMoonshotsRef.current = moonshots;
  }, [moonshots]);

  const timeSince = (date) => {
    const now = new Date();
    const createdAt = new Date(date);
    const diffInSeconds = (now - createdAt) / 1000;

    if (diffInSeconds < 60) {
      return `${Math.floor(diffInSeconds)} seconds`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutes`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hours`;
    } else {
      return `${Math.floor(diffInSeconds / 86400)} days`;
    }
  };

  const handleRowClick = (contractAddress) => {
    const url = `https://www.dextools.io/app/en/ether/pair-explorer/${contractAddress}`;
    window.open(url, '_blank'); // Open in a new tab or window
  };

  const sortMoonshots = (field) => {
    const order = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const getColorClass = (currentValue, previousValue) => {
    if (currentValue > previousValue) {
      return 'green-fade';
    } else if (currentValue < previousValue) {
      return 'red-fade';
    }
    return '';
  };

  // **Updated Filtering Logic**
  const filteredMoonshots = useMemo(() => {
    return moonshots.filter((moonshot) => {
      // Filter by label (case-insensitive)
      if (labelFilter && !moonshot.label.toLowerCase().includes(labelFilter.toLowerCase())) {
        return false;
      }

      // Filter by MC Now
      const mcClosing = Number(moonshot.mcClosing);
      if (mcnowFilter.min && mcClosing < Number(mcnowFilter.min)) return false;
      if (mcnowFilter.max && mcClosing > Number(mcnowFilter.max)) return false;

      // Filter by MC Open
      const mcOpen = Number(moonshot.mcOpen);
      if (mcopenFilter.min && mcOpen < Number(mcopenFilter.min)) return false;
      if (mcopenFilter.max && mcOpen > Number(mcopenFilter.max)) return false;

      // Filter by Price Change
      const priceChange = Number(moonshot.priceChange);
      if (priceChangeFilter.min && priceChange < Number(priceChangeFilter.min)) return false;
      if (priceChangeFilter.max && priceChange > Number(priceChangeFilter.max)) return false;

      // Filter by Gains (mcClosing / mcOpen)
      const gains = moonshot.mcClosing && moonshot.mcOpen ? moonshot.mcClosing / moonshot.mcOpen : 0;
      if (gainsFilter.min && gains < Number(gainsFilter.min)) return false;
      if (gainsFilter.max && gains > Number(gainsFilter.max)) return false;

      // Existing maxGainsFilter (if applicable)
      const xGained = Number(moonshot.xGained) || 0;
      if (maxGainsFilter.min && gains < Number(maxGainsFilter.min)) return false;
      if (maxGainsFilter.max && gains > Number(maxGainsFilter.max)) return false;

      // **New Filter 1:** Gains ≥ xGained / 2
      if (gainsThresholdFilter) {
        if (xGained === 0) return false; // Avoid division by zero
        if (gains < xGained / 2) return false;
      }

      // **New Filter 2:** MC Now ≤ MC Open
      if (mcNowLessThanOrEqualFilter && mcClosing > mcOpen) {
        return false;
      }

      // **New Filter 1:** Gains < xGained / 2
      if (gains2ThresholdFilter) {
        if (xGained === 0) return false; // Avoid division by zero
        if (gains > xGained / 2) return false;
      }

      // **New Filter 2:** MC Now > MC Open
      if (mcNowGreaterThanOrEqualFilter && mcClosing < mcOpen) {
        return false;
      }

      return true;
    });
  }, [
    moonshots,
    labelFilter,
    mcnowFilter,
    mcopenFilter,
    priceChangeFilter,
    gainsFilter,
    maxGainsFilter,
    gainsThresholdFilter,
    mcNowLessThanOrEqualFilter,
    gains2ThresholdFilter,
    mcNowGreaterThanOrEqualFilter
  ]);

  // **Updated Sorting Logic (Remains Intact)**
  const sortedMoonshots = useMemo(() => {
    const sorted = [...filteredMoonshots].sort((a, b) => {
      const orderMultiplier = sortOrder === 'asc' ? 1 : -1;

      if (sortField === 'gains') {
        const aGains = a.mcClosing && a.mcOpen ? a.mcClosing / a.mcOpen : 0;
        const bGains = b.mcClosing && b.mcOpen ? b.mcClosing / b.mcOpen : 0;
        return (aGains - bGains) * orderMultiplier;
      }

      if (sortField === 'maxGains') {
        const aMaxGains = a.xGained || 0;
        const bMaxGains = b.xGained || 0;
        return (aMaxGains - bMaxGains) * orderMultiplier;
      }

      if (sortField === 'change') {
        const aChange = a.priceChange || 0;
        const bChange = b.priceChange || 0;
        return (aChange - bChange) * orderMultiplier;
      }

      if (sortField === 'mcClosing' || sortField === 'mcOpen') {
        return (Number(a[sortField]) - Number(b[sortField])) * orderMultiplier;
      }

      const aValue = a[sortField] !== undefined ? a[sortField] : '';
      const bValue = b[sortField] !== undefined ? b[sortField] : '';

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * orderMultiplier;
      }

      return 0;
    });

    return sorted;
  }, [filteredMoonshots, sortField, sortOrder]);

  // Frontend function to call backend and delete the document
const handleDeleteClick = async (documentId) => {
  try {
    const response = await axios.delete(`${baseUrl}/api/deleteDocument/history`, {
      data: { documentId } // Pass document ID in the request body
    });

    if (response.status === 200) {
      console.log('Document deleted successfully:', response.data.message);
      // Optionally, remove the deleted document from your local state to update the UI
      setMoonshots(prevMoonshots => prevMoonshots.filter(moonshot => moonshot._id !== documentId));
    } else {
      console.error('Error deleting document:', response.data.error);
    }
  } catch (error) {
    console.error('Failed to delete document:', error);
  }
};

  return (
    <div className="home-container">
      <AllHistory/>      
     {/* Toggle Button for Filters */}
     <button
        className="filter-toggle-button"
        onClick={() => setShowFilters(!showFilters)}
      >
        {showFilters ? 'Hide Filters' : 'Show Filters'} <FontAwesomeIcon icon={faFilter} />
      </button>

      {/* Filters Section */}
      {showFilters && (
      <div className="filters">
        <div className="filtersBodyMain">
          <div className="filterslabel">
            <div className="filter-item">
              <label htmlFor="labelFilter">Label:</label>
              <input
                id="labelFilter"
                type="text"
                placeholder="Enter label"
                value={labelFilter}
                onChange={(e) => setLabelFilter(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="filtersBodyMain">
          <div className="filtersMc">
            <div className="filter-item">
              <label>MC Close:</label>
              <input
                type="number"
                placeholder="Min"
                value={mcnowFilter.min}
                onChange={(e) => setMcnowFilter({ ...mcnowFilter, min: e.target.value })}
              />
              <input
                type="number"
                placeholder="Max"
                value={mcnowFilter.max}
                onChange={(e) => setMcnowFilter({ ...mcnowFilter, max: e.target.value })}
              />
            </div>

            {/* MC Open Filter */}
            <div className="filter-item">
              <label>MC Open:</label>
              <input
                type="number"
                placeholder="Min"
                value={mcopenFilter.min}
                onChange={(e) => setMcopenFilter({ ...mcopenFilter, min: e.target.value })}
              />
              <input
                type="number"
                placeholder="Max"
                value={mcopenFilter.max}
                onChange={(e) => setMcopenFilter({ ...mcopenFilter, max: e.target.value })}
              />
            </div>
          </div>

          {/* Price Change Filter */}
          <div className="filtersMc">
            <div className="filter-item">
              <label>Change (%):</label>
              <input
                type="number"
                placeholder="Min Price Change %"
                value={priceChangeFilter.min}
                onChange={(e) => setPriceChangeFilter({ ...priceChangeFilter, min: e.target.value })}
              />
              <input
                type="number"
                placeholder="Max Price Change %"
                value={priceChangeFilter.max}
                onChange={(e) => setPriceChangeFilter({ ...priceChangeFilter, max: e.target.value })}
              />
            </div>

            {/* Gains Filter */}
            <div className="filter-item">
              <label>Gains:</label>
              <input
                type="number"
                placeholder="Min X's Gained"
                value={gainsFilter.min}
                onChange={(e) => setGainsFilter({ ...gainsFilter, min: e.target.value })}
              />
              <input
                type="number"
                placeholder="Max X's Gained"
                value={gainsFilter.max}
                onChange={(e) => setGainsFilter({ ...gainsFilter, max: e.target.value })}
              />
            </div>
          </div>
        </div>

        {/* **New Filters Section** */}
        <div className="filtersBodyMain">
          {/* Gains Threshold Filter */}
          <div className="filter-item">
            <label>
              <input
                type="checkbox"
                checked={gainsThresholdFilter}
                onChange={(e) => setGainsThresholdFilter(e.target.checked)}
              />
              Show Gains ≥ Top / 2
            </label>
          </div>

          {/* MC Now ≤ MC Open Filter */}
          <div className="filter-item">
            <label>
              <input
                type="checkbox"
                checked={mcNowLessThanOrEqualFilter}
                onChange={(e) => setMcNowLessThanOrEqualFilter(e.target.checked)}
              />
              Show MC Close ≤ MC Open
            </label>
          </div>
        </div>


              {/* **New Filters Section** */}
              <div className="filtersBodyMain">
          {/* Gains Threshold Filter */}
          <div className="filter-item">
            <label>
              <input
                type="checkbox"
                checked={gains2ThresholdFilter}
                onChange={(e) => setGains2ThresholdFilter(e.target.checked)}
              />
              Show Gains ≤ Top / 2
            </label>
          </div>

          {/* MC Now ≤ MC Open Filter */}
          <div className="filter-item">
            <label>
              <input
                type="checkbox"
                checked={mcNowGreaterThanOrEqualFilter}
                onChange={(e) => setMcNowGreaterThanOrEqualFilter(e.target.checked)}
              />
              Show MC Close &gt; MC Open
            </label>
          </div>
        </div>
        </div>
      )}

      {/* Table Section */}
      <div className="moonshot-table-container">
        <table className="moonshot-table">
          <thead>
            <tr>
              <th>#</th>
              <th onClick={() => sortMoonshots('tokenName')}>
                Token Name{' '}
                {sortField === 'tokenName' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
              <th onClick={() => sortMoonshots('label')}>
                Label{' '}
                {sortField === 'label' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
              <th onClick={() => sortMoonshots('meta')}>
                Meta?{' '}
                {sortField === 'meta' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
              <th onClick={() => sortMoonshots('contractAddress')}>
                Contract Address{' '}
                {sortField === 'contractAddress' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
              <th onClick={() => sortMoonshots('mcClosing')}>
                MC Close{' '}
                {sortField === 'mcClosing' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
              <th onClick={() => sortMoonshots('mcOpen')}>
                MC Open{' '}
                {sortField === 'mcOpen' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
              <th onClick={() => sortMoonshots('risk')}>
                Risk Level{' '}
                {sortField === 'risk' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
              <th onClick={() => sortMoonshots('_createdAt')}>
                Time in DB{' '}
                {sortField === '_createdAt' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
            <th onClick={() => sortMoonshots('change')}>
                Change{' '}
                {sortField === 'change' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
              <th onClick={() => sortMoonshots('gains')}>
                Gains{' '}
                {sortField === 'gains' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
              <th onClick={() => sortMoonshots('maxGains')}>
                Top{' '}
                {sortField === 'maxGains' && (
                  <FontAwesomeIcon icon={sortOrder === 'asc' ? faArrowDown : faArrowUp} />
                )}
              </th>
              {userId === 'pziPDtOgJiX06oGuJxNwxE' && (
              <th>
                Del
              </th>
                     )}
            </tr>
          </thead>
          <tbody>
            {sortedMoonshots.map((moonshot, index) => {
              const isNew = (new Date() - new Date(moonshot._createdAt)) < 3600000; // 1 hour in milliseconds

              return (
                <tr
                  key={moonshot._id}
                  className="clickable-row"
                  onClick={() => handleRowClick(moonshot.contractAddress)}
                >
                  <td>{index + 1}</td>
                  <td>
                    {isNew && <FontAwesomeIcon icon={faStar} style={{ color: 'gold', marginRight: '4px' }} />} {/* New icon */}
                    {moonshot.meta === "possible meta" ? (
                      <FontAwesomeIcon icon={faEye} style={{ color: 'blue', marginRight: '4px' }} />
                    ) : moonshot.meta ? (
                      <FontAwesomeIcon icon={faFire} style={{ color: 'red', marginRight: '4px' }} />
                    ) : null}
                    {moonshot.tokenName}
                  </td>
                  <td>{moonshot.label}</td>
                  <td>
                    {moonshot.meta ? moonshot.meta : "No"}
                  </td>
                  <td>{moonshot.contractAddress.slice(0, 4)}...{moonshot.contractAddress.slice(-4)}</td>
                  <td className={getColorClass(moonshot.mcClosing, previousMoonshotsRef.current[index]?.mcClosing)}>
                    <div>$<AnimatedNumber value={moonshot.mcClosing} /></div>
                  </td>
                  <td className={getColorClass(moonshot.mcOpen, previousMoonshotsRef.current[index]?.mcOpen)}>
                    $<AnimatedNumber value={moonshot.mcOpen} />
                  </td>
                  <td>{moonshot.risk}</td>
                  <td>
                    {timeSince(moonshot.createdAt)}
                    {moonshot.detectedHourIcon ? moonshot.detectedHourIcon : null}
                  </td>
          <td className={getColorClass(moonshot.priceChange, 0)}>
                    <AnimatedNumber value={moonshot.priceChange?.toFixed(2)} />%
                  </td>
                  <td className={getColorClass(
                    moonshot.mcClosing / moonshot.mcOpen,
                    previousMoonshotsRef.current[index]?.mcClosing / previousMoonshotsRef.current[index]?.mcOpen
                  )}>
                    <AnimatedNumber value={(moonshot.mcClosing / moonshot.mcOpen - 1).toFixed(2)} /> X's
                  </td>
                  <td>
                    <AnimatedNumber value={moonshot.xGained?.toFixed(2)} /> X's
                  </td>
                  {userId === 'pziPDtOgJiX06oGuJxNwxE' && (
                    <td>
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          // Call your delete handler function here
                          handleDeleteClick(moonshot._id);
                        }}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
            {sortedMoonshots.length === 0 && (
              <tr>
                <td colSpan="12" style={{ textAlign: 'center' }}>No tokens match the filter criteria.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default HistoryTokens;
