import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faStar, faFire, faEye, faInfoCircle, faRepeat } from '@fortawesome/free-solid-svg-icons';
import { io } from 'socket.io-client'; 
import AnimatedNumber from './animatedNumber';
import SignupModal from './signupModal'; // Import the SignupModal component
import './home.css';
import { AuthContext } from '../authContext'; // Adjust the path as necessary

function Home() {
  const [moonshots, setMoonshots] = useState([]);
  const previousMoonshotsRef = useRef([]);
  const [sortField, setSortField] = useState('tokenName'); // Default sort field
  const [sortOrder, setSortOrder] = useState('asc'); // Default sort order

  const { isAuthenticated } = useContext(AuthContext); // Access authentication state
  const [showSignupModal, setShowSignupModal] = useState(false); 
  const [showInfoModal, setShowInfoModal] = useState(false); 

  useEffect(() => {
    // Connect to the Socket.IO server
    const socket = io('https://www.trendscout.club', {
      withCredentials: true,
      extraHeaders: {
        "my-custom-header": "abcd"
      }
    });
  
    // Fetch initial data and sort by 'gains' descending
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_HOME_MOONSHOTS_API);
        const data = await response.json();
        const fetchedMoonshots = data.result || [];

        // Calculate maxGains and add it to each moonshot
        const processedMoonshots = fetchedMoonshots.map(moonshot => {
          const currentGains = moonshot.mcClosing && moonshot.mcOpen ? moonshot.mcClosing / moonshot.mcOpen : 0;
          return { ...moonshot, gains: currentGains, maxGains: moonshot.xGained };
        });

        // Sort the moonshots by 'gains' descending
        const sortedMoonshots = [...processedMoonshots].sort((a, b) => b.gains - a.gains);

        setMoonshots(sortedMoonshots);
        previousMoonshotsRef.current = sortedMoonshots; // Initialize the ref with sorted data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  
    // Listen for updates from the server
    socket.on('moonshotsUpdated', (updatedMoonshots) => {
      previousMoonshotsRef.current = moonshots; // Save previous state before updating

      // Calculate and update maxGains for each moonshot
      const processedMoonshots = updatedMoonshots.map(moonshot => {
        const currentGains = moonshot.mcClosing && moonshot.mcOpen ? moonshot.mcClosing / moonshot.mcOpen : 0;
        return { ...moonshot, gains: currentGains, maxGains: moonshot.xGained };
      });

      setMoonshots(processedMoonshots); // Update state with new moonshots
    });
  
    return () => {
      socket.disconnect(); // Clean up on component unmount
    };
  }, []);
  

  // Track changes in moonshots
  useEffect(() => {
    previousMoonshotsRef.current = moonshots;
  }, [moonshots]);

  // Push updates every minute
  useEffect(() => {
    const intervalId = setInterval(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_HOME_MOONSHOTS_API);
          const data = await response.json();
          const updatedMoonshots = data.result || [];

          // Calculate and update maxGains for each moonshot
          const processedMoonshots = updatedMoonshots.map(moonshot => {
            const currentGains = moonshot.mcClosing && moonshot.mcOpen ? moonshot.mcClosing / moonshot.mcOpen : 0;
            return { ...moonshot, gains: currentGains, maxGains: moonshot.xGained };
          });

          previousMoonshotsRef.current = moonshots; // Save previous state before updating
          setMoonshots(processedMoonshots); // Update state with new moonshots
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [moonshots]);

  const timeSince = (date) => {
    const now = new Date();
    const createdAt = new Date(date);
    const diffInSeconds = (now - createdAt) / 1000;

    if (diffInSeconds < 60) {
      return `${Math.floor(diffInSeconds)} seconds`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutes`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hours`;
    } else {
      return `${Math.floor(diffInSeconds / 86400)} days`;
    }
  };

  const handleRowClick = (contractAddress) => {
    if (!isAuthenticated) {
      setShowSignupModal(true); // Show signup popup for non-logged-in users
    } else {
      const url = `https://www.dextools.io/app/en/ether/pair-explorer/${contractAddress}`;
      window.open(url, '_blank'); // Open in a new tab or window
    }
  };

  const handleHeaderClick = (field) => {
    if (!isAuthenticated) {
      setShowSignupModal(true); // Show signup popup for non-logged-in users
    } else {
      sortMoonshots(field);
    }
  };

  const sortMoonshots = (field) => {
    const order = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);

    const sortedMoonshots = [...moonshots].sort((a, b) => {
      const aGains = a.mcClosing && a.mcOpen ? a.mcClosing / a.mcOpen : 0;
      const bGains = b.mcClosing && b.mcOpen ? b.mcClosing / b.mcOpen : 0;

      if (field === 'gains') {
        return order === 'asc' ? aGains - bGains : bGains - aGains;
      }

      if (field === 'maxGains') {
        return order === 'asc' ? a.maxGains - b.maxGains : b.maxGains - a.maxGains;
      }

      const aChange = a.priceChange || 0;
      const bChange = b.priceChange || 0;
      
      
      if (field === 'change') {
        return order === 'asc' ? aChange - bChange : bChange - aChange;
      }      

      if (field === 'mcClosing' || field === 'mcOpen') {
        return order === 'asc'
          ? Number(a[field]) - Number(b[field])
          : Number(b[field]) - Number(a[field]);
      }

      const aValue = a[field] !== undefined ? a[field] : '';
      const bValue = b[field] !== undefined ? b[field] : '';

      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    setMoonshots(sortedMoonshots);
  };

  const getColorClass = (currentValue, previousValue) => {
    if (currentValue > previousValue) {
      return 'green-fade';
    } else if (currentValue < previousValue) {
      return 'red-fade';
    }
    return '';
  };

  const toggleInfoModal = () => {
    setShowInfoModal(!showInfoModal); // Toggle info modal visibility
  };

  return (
    <div className="home-container-pages">
      <h2>
        Current Runners
        <FontAwesomeIcon 
          icon={faInfoCircle} 
          className="info-icon" 
          onClick={toggleInfoModal} 
          style={{ marginLeft: '10px', cursor: 'pointer' }} 
        />
      </h2>
      <div className="moonshot-table-container">
        <table className="moonshot-table">
          <thead>
            <tr>
              <th>#</th>
              <th onClick={() => handleHeaderClick('tokenName')}>
                Token Name {sortField === 'tokenName' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th>
              <th onClick={() => handleHeaderClick('label')}>
                Label {sortField === 'label' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th>
              <th onClick={() => handleHeaderClick('meta')}>
                Meta? {sortField === 'meta' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th>
              <th onClick={() => handleHeaderClick('contractAddress')}>
                CA {sortField === 'contractAddress' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th>
              <th onClick={() => handleHeaderClick('mcClosing')}>
                MC Now {sortField === 'mcClosing' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th>
              <th onClick={() => handleHeaderClick('mcOpen')}>
                MC Open {sortField === 'mcOpen' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th>
              <th onClick={() => handleHeaderClick('risk')}>
                Risk {sortField === 'risk' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th>
              {/* <th onClick={() => handleHeaderClick('trend')}>
                Trend {sortField === 'trend' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th> */}
              <th onClick={() => handleHeaderClick('_createdAt')}>
                Time in DB {sortField === '_createdAt' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th>
              <th onClick={() => handleHeaderClick('daysAgo')}>
             Deployed {sortField === 'daysAgo' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
           </th>
              <th onClick={() => handleHeaderClick('change')}>
                Change {sortField === 'change' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th>
              <th onClick={() => handleHeaderClick('gains')}>
                Gains {sortField === 'gains' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
              </th>
       {/*    <th onClick={() => sortMoonshots('maxGains')}>
         Top Gains {sortField === 'maxGains' && (sortOrder === 'asc' ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />)}
           </th>*/}
            </tr>
          </thead>
          <tbody>
            {moonshots.map((moonshot, index) => {
              const isNew = (new Date() - new Date(moonshot._createdAt)) < 3600000; // 1 hour in milliseconds
              const isRowVisible = index < 4 || isAuthenticated; // Show full info for first 3 rows or if logged in
              const rowClass = !isRowVisible ? 'blurred-row' : ''; // Apply blur class if not logged in and row is beyond 3rd row

              return (
                <tr 
                  key={moonshot._id} 
                  className={`clickable-row ${rowClass}`} 
                  onClick={() => handleRowClick(moonshot.contractAddress)}
                >
                  <td>{index + 1}</td>
                  <td>
                    {isNew && <FontAwesomeIcon icon={faStar} style={{ color: 'gold', marginRight: '4px' }} />} {/* Display the new icon */}
                    {moonshot.meta === "possible meta" ? (
                      <FontAwesomeIcon icon={faEye} style={{ color: 'blue', marginRight: '4px' }} /> 
                    ) : moonshot.meta ? (
                      <FontAwesomeIcon icon={faFire} style={{ color: 'red', marginRight: '4px' }} />
                    ) : null}
                     {moonshot.daysAgo > 30 && <FontAwesomeIcon icon={faRepeat} style={{ color: 'green', marginRight: '4px' }} />}
                    {isRowVisible ? moonshot.tokenName : '********'}
                  </td>
                  <td>{isRowVisible ? moonshot.label : '********'}</td>
                  <td>{isRowVisible ? (moonshot.meta ? moonshot.meta : "No") : '********'}</td>
                  <td>{isRowVisible ? `${moonshot.contractAddress.slice(0,4)}...${moonshot.contractAddress.slice(-5)}` : '************'}</td>
                  <td className={getColorClass(moonshot.mcClosing, previousMoonshotsRef.current[index]?.mcClosing)}>
                    {isRowVisible ? (
                      <div>$<AnimatedNumber value={moonshot.mcClosing} /></div>
                    ) : '*******'}
                  </td>
                  <td className={getColorClass(moonshot.mcOpen, previousMoonshotsRef.current[index]?.mcOpen)}>$
                    {isRowVisible ? <AnimatedNumber value={moonshot.mcOpen} /> : '*******'}
                  </td>
                  <td>{isRowVisible ? moonshot.risk : '*******'}</td>
                  {/* <td>{isRowVisible ? moonshot.trend : '*******'}</td> */}
                  <td>{isRowVisible ? timeSince(moonshot._createdAt) : '*******'}
                  {moonshot.detectedHourIcon ? moonshot.detectedHourIcon : null}
                  </td>
                  <td>
                    {isRowVisible ? moonshot.daysAgo !== undefined ? `${moonshot.daysAgo} days` : 'NA' : '*******'}
                  </td>
                  <td className={getColorClass(moonshot.priceChange, 0)}>
                    {isRowVisible ? (
                      <>
                        <AnimatedNumber value={moonshot.priceChange ? moonshot.priceChange.toFixed(2) : '0.00'} />%
                      </>
                    ) : '*******'}
                  </td>
                  <td className={getColorClass(
                    moonshot.mcClosing / moonshot.mcOpen, 
                    previousMoonshotsRef.current[index]?.mcClosing / previousMoonshotsRef.current[index]?.mcOpen
                  )}>
                    {isRowVisible ? <AnimatedNumber value={(moonshot.mcClosing / moonshot.mcOpen - 1).toFixed(2)} />  : '*******'} X
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Display signup modal */}
      {showSignupModal && <SignupModal onClose={() => setShowSignupModal(false)} />}
              {/* Info Modal */}
      {showInfoModal && (
        <div className="info-modal">
          <div className="info-modal-content">
            <p>With thousands of tokens deployed every day, Trend Scout filters out approximately 97% of them to display ONLY the ones with highest potential to bring you gains.</p>
            <div className='infomodalmain'>  
              <div className='infomodalicons'> ⏰ Deployed during HOT hours </div>
              <div className='infomodalicons'> <FontAwesomeIcon icon={faStar} style={{ color: 'gold', marginRight: '4px' }} /> Detected under an hour ago </div>
              <div className='infomodalicons'> <FontAwesomeIcon icon={faEye} style={{ color: 'blue', marginRight: '4px' }} /> Possible Meta, signs of doing 10x + </div>
              <div className='infomodalicons'> <FontAwesomeIcon icon={faFire} style={{ color: 'red', marginRight: '4px' }} /> Meta, meets criteria of tokens which set a new trend </div>
              <div className='infomodalicons'> <FontAwesomeIcon icon={faRepeat} style={{ color: 'green', marginRight: '4px' }} /> Deployed over a month ago </div>
            </div>
            <button onClick={toggleInfoModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
