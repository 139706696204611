import React, { useState } from 'react';
import axios from 'axios';
import './performanceSim.css'; // Optional: for styling the component

const PerformanceSim = () => {
    const [investment, setInvestment] = useState('');
    const [firstSellSetting, setFirstSellSetting] = useState('');
    const [secondSellSetting, setSecondSellSetting] = useState('');
    const [thirdSellSetting, setThirdSellSetting] = useState('');
    const [tokenPerformance, setTokenPerformance] = useState([]);
    const [summary, setSummary] = useState(null);
    const [error, setError] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [allTokens, setAllTokens] = useState([]);
    const [days, setDays] = useState(0);
    const [startHour, setStartHour] = useState({ hour: '12', period: 'AM' });
    const [endHour, setEndHour] = useState({ hour: '11', period: 'PM' });
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  
    // Helper function to convert AM/PM time to 24-hour format
    const convertTo24HourFormat = (time) => {
      const { hour, period } = time;
      let hourInt = parseInt(hour, 10);
      if (period === 'PM' && hourInt !== 12) {
        hourInt += 12;
      } else if (period === 'AM' && hourInt === 12) {
        hourInt = 0;
      }
      return hourInt;
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!investment || !firstSellSetting || !secondSellSetting || !thirdSellSetting) {
        setError('Please fill in all fields');
        return;
      }
  
      try {
        // Fetch token data from Sanity API
        const response = await axios.get(process.env.REACT_APP_HOME_ALLTOKENS_API);
        const fetchedTokens = response.data.result;
        setAllTokens(fetchedTokens);
  
        // Filter tokens based on the selected time frame
        const currentTime = new Date();
        // Filter tokens based on the selected time frame
        const filteredTokens = fetchedTokens.filter(token => {
          const createdAt = new Date(token._createdAt); // Extract the date from the createdAt field
          const timeDiff = (currentTime - createdAt) / (1000 * 60 * 60 * 24); // Convert time to days

          // Convert start and end times to 24-hour format
          const startHour24 = convertTo24HourFormat(startHour);
          const endHour24 = convertTo24HourFormat(endHour);
          const tokenHour = createdAt.getUTCHours(); // Get hour in UTC

          // Calculate if token is within the specified day range
          const isWithinDays = timeDiff <= days;

          // Check if the token's hour falls within the selected time range
          let isWithinHours;

          if (startHour24 <= endHour24) {
            // Standard case: check if the token is between start and end times
            isWithinHours = (tokenHour >= startHour24 && tokenHour <= endHour24);
          } else {
            // Overnight case: check if the token is either after startHour or before endHour
            isWithinHours = (tokenHour >= startHour24 || tokenHour <= endHour24);
          }

          return isWithinDays && isWithinHours;
        });

  
        // Calculate PNL for each token
        const calculatedPerformance = filteredTokens
          .filter(token => selectedLabels.includes(token.label))
          .map((token) => {
            let remainingInvestment = parseFloat(investment);
            let totalSell = 0;
  
            // First sell
            if (token.xGained >= firstSellSetting && firstSellSetting > 0) {
              const valueAtFirstSell = remainingInvestment * firstSellSetting;
              const firstSell = valueAtFirstSell / 2;
              totalSell += firstSell;
              remainingInvestment = remainingInvestment / 2;
            }
  
            // Second sell
            if (token.xGained >= secondSellSetting && secondSellSetting > 0) {
              const valueAtSecondSell = remainingInvestment * secondSellSetting;
              const secondSell = valueAtSecondSell / 2;
              totalSell += secondSell;
              remainingInvestment = remainingInvestment / 2;
            }
  
            // Third sell
            if (token.xGained >= thirdSellSetting) {
              const valueAtThirdSell = remainingInvestment * thirdSellSetting;
              const thirdSell = valueAtThirdSell;
              totalSell += thirdSell;
              remainingInvestment = remainingInvestment / 2;
            }
  
            const pnl = totalSell - parseFloat(investment);
  
            return {
              tokenName: token.tokenName,
              invested: parseFloat(investment),
              xGained: token.xGained,
              pnl,
              contractAddress: token.contractAddress,
              mcOpen: token.mcOpen,
              label: token.label,
            };
          });
  
        // Summary calculations
        const totalTokensBought = calculatedPerformance.length;
        const totalInvested = calculatedPerformance.reduce((sum, token) => sum + token.invested, 0);
        const totalPNL = calculatedPerformance.reduce((sum, token) => sum + token.pnl, 0);
        const totalGain = calculatedPerformance
          .filter((token) => token.pnl > 0)
          .reduce((sum, token) => sum + token.pnl, 0);
        const totalLoss = calculatedPerformance
          .filter((token) => token.pnl < 0)
          .reduce((sum, token) => sum + token.pnl, 0);
  
        setTokenPerformance(calculatedPerformance);
        setSummary({
          totalTokensBought,
          totalInvested,
          totalPNL,
          totalGain,
          totalLoss,
        });
        setError(null);
      } catch (err) {
        setError('Error fetching data');
      }
    };
  
    const handleRowClick = (contractAddress) => {
      const url = `https://www.dextools.io/app/en/ether/pair-explorer/${contractAddress}`;
      window.open(url, '_blank');
    };
  
    const handleLabelChange = (label) => {
      setSelectedLabels((prevSelected) =>
        prevSelected.includes(label)
          ? prevSelected.filter((l) => l !== label)
          : [...prevSelected, label]
      );
    };

    const handleSelectAll = () => {
      if (selectAll) {
        setSelectedLabels([]); // Deselect all
      } else {
        setSelectedLabels(allTokens.map(token => token.label)); // Select all
      }
      setSelectAll(!selectAll); // Toggle the select all state
    };
    

    const sortTable = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedPerformance = [...tokenPerformance].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });


    


  return (
    <div className="performance-sim">
      <h2>Performance Simulation</h2>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="investment">Investment Amount:</label>
          <input
            type="number"
            id="investment"
            value={investment}
            onChange={(e) => setInvestment(e.target.value)}
            placeholder="Enter investment amount"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="firstSellSetting">First Sell Setting 50% Position</label>
          <input
            type="number"
            id="firstSellSetting"
            value={firstSellSetting}
            onChange={(e) => setFirstSellSetting(e.target.value)}
            placeholder="Enter number of X's Desired"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="secondSellSetting">Second Sell Setting 50% Position</label>
          <input
            type="number"
            id="secondSellSetting"
            value={secondSellSetting}
            onChange={(e) => setSecondSellSetting(e.target.value)}
            placeholder="Enter number of X's Desired"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="thirdSellSetting">Third Sell Setting 100% Position</label>
          <input
            type="number"
            id="thirdSellSetting"
            value={thirdSellSetting}
            onChange={(e) => setThirdSellSetting(e.target.value)}
            placeholder="Enter number of X's Desired"
            required
          />
        </div>

            {/* Select Labels */}
            <div className="form-group-labels">
              {Array.from(new Set(allTokens.map(token => token.label)))
                .sort((a, b) => a.localeCompare(b))
                .map((label, index) => (
                  <div key={index} className="label-item">
                    <label>
                      <input
                        type="checkbox"
                        value={label}
                        checked={selectedLabels.includes(label)}
                        onChange={() => handleLabelChange(label)}
                      />
                      {label}
                    </label>
                  </div>
                ))}

              {/* Conditionally render the "Select All" checkbox if there are labels */}
              {allTokens.length > 0 && (
                <div className="form-group-labels">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                    Select All
                  </label>
                </div>
              )}
            </div>





        {/* Time Frame Selection */}
        <div className="form-group">
          <label htmlFor="days">Days since added:</label>
          <input
            type="number"
            id="days"
            value={days}
            onChange={(e) => setDays(e.target.value)}
            placeholder="Enter number of days"
            min="0"
          />
        </div>
        {/* Time Frame Selection with AM/PM */}
        <div className="form-group">
          <label htmlFor="startHour">Start Hour:</label>
          <div>
            <select value={startHour.hour} onChange={(e) => setStartHour({ ...startHour, hour: e.target.value })}>
              {[...Array(12).keys()].map(i => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </select>
            <select value={startHour.period} onChange={(e) => setStartHour({ ...startHour, period: e.target.value })}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="endHour">End Hour:</label>
          <div>
            <select value={endHour.hour} onChange={(e) => setEndHour({ ...endHour, hour: e.target.value })}>
              {[...Array(12).keys()].map(i => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </select>
            <select value={endHour.period} onChange={(e) => setEndHour({ ...endHour, period: e.target.value })}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </div>
        </div>

        <button type="submit">Simulate Performance</button>
      </form>

      {error && <p className="error">{error}</p>}

      {summary && (
        <div className="summary">
        <h3>Summary</h3>
        <p>Total Tokens Bought: {summary.totalTokensBought}</p>
        <p>Total Invested: {parseFloat(summary.totalInvested).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
        <p>Total Gain: {parseFloat(summary.totalGain).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
        <p>Total Loss:  {parseFloat(summary.totalLoss).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
        <p>Total PNL: {parseFloat(summary.totalPNL).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
      </div>
      )}

{tokenPerformance.length > 0 && (
                <div className="performance-results">
                    <table>
                        <thead>
                            <tr>
                                <th onClick={() => sortTable('tokenName')}>Token Name</th>
                                <th onClick={() => sortTable('mcOpen')}>Starting MC</th>
                                <th onClick={() => sortTable('label')}>Label</th>
                                <th onClick={() => sortTable('xGained')}>X Gained</th>
                                <th onClick={() => sortTable('pnl')}>PNL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedPerformance.map((token, index) => (
                                <tr key={index} onClick={() => handleRowClick(token.contractAddress)} style={{ cursor: 'pointer' }}>
                                    <td>{token.tokenName}</td>
                                    <td>{parseFloat(token.mcOpen).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                    <td>{token.label}</td>
                                    <td>{token.xGained?.toFixed(2)}</td>
                                    <td style={{ color: token.pnl >= 0 ? 'green' : 'red' }}>
                                        ${token.pnl >= 0 ? '+' : ''}{token.pnl.toFixed(2)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
        </div>
      )}
    </div>
  );
};

export default PerformanceSim;
