import React, { useEffect, useState } from 'react';
import './animatedNumber.css';

const AnimatedNumber = ({ value }) => {
  // Convert the value to a number immediately on component mount
  const [displayValue, setDisplayValue] = useState(Number(value)); // Initial state set as a number
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const numericValue = Number(value); // Convert incoming value to a number

    // Only animate if the numeric value has changed
    if (displayValue !== numericValue) {
      setIsAnimating(true);
      const timer = setTimeout(() => {
        setDisplayValue(numericValue); // Set displayValue to the numeric value
        setIsAnimating(false);
      }, 1000); // Duration of the animation

      return () => clearTimeout(timer);
    }
  }, [value]); // Listen for changes to value

  return (
    <div className={`animated-number ${isAnimating ? 'spin' : ''}`}>
      {displayValue.toLocaleString('en-US')} {/* Format with commas */}
    </div>
  );
};

export default AnimatedNumber;
