import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import logo from '../logo.jpg';
import './trending.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Trending() {
  const [trendingItems, setTrendingItems] = useState([]);

  useEffect(() => {
    const fetchTrendingItems = async () => {
      const batchSize = 30; // Define the number of documents to fetch in each batch
      let allTrendingItems = []; // Array to store all fetched items
      let currentBatch = 0; // Counter to keep track of batches

      while (true) {
        // Fetch a batch of documents
        const query = `*[_type == 'allTokens' && (xGained > 0 && (priceChange > 5)][${currentBatch * batchSize}...${(currentBatch + 1) * batchSize}]`;
        
        try {
          const response = await fetch(`https://5f89dy2h.api.sanity.io/v2021-03-25/data/query/production?query=${encodeURIComponent(query)}`);
          const data = await response.json();

          // If no more documents are returned, break the loop
          if (data.result.length === 0) {
            break;
          }

          allTrendingItems = [...allTrendingItems, ...data.result]; // Append new items to the list
          currentBatch++; // Move to the next batch
        } catch (error) {
          console.error('Error fetching data:', error);
          break; // Break the loop on error
        }
      }

      setTrendingItems(allTrendingItems); // Update the state with all fetched items
    };

    // Initial fetch when component mounts
    fetchTrendingItems();

    // Set interval to run fetch every 5 minutes (300,000 milliseconds)
    const intervalId = setInterval(fetchTrendingItems, 300000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: Math.min(trendingItems.length, 5),
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: Math.min(trendingItems.length, 4),
        },
      },
    ],
  };

  const getBackgroundColor = (priceChange) => {
    if (priceChange > 0) {
      return 'green';
    } else if (priceChange < 0) {
      return 'red';
    } else {
      return 'blue';
    }
  };

  const handleRowClick = (contractAddress) => {
    const url = `https://www.dextools.io/app/en/ether/pair-explorer/${contractAddress}`;
    window.open(url, '_blank'); // Open in a new tab or window
  };

  return (
    <div className="trending-carousel">
      <h2>Trending Moonshots</h2>
      {trendingItems.length < 5 ? (
        <div className='badMessage'>Bad Market Conditions for Memes</div>
      ) : (
        <Slider {...settings}>
          {trendingItems.map((item) => (
            <div 
              key={item._id} 
              className="carousel-item"
              onClick={() => handleRowClick(item.contractAddress)} // Trigger handleRowClick on click
              style={{ cursor: 'pointer' }} // Add a pointer cursor to indicate it's clickable
            >
              <div className='token'>
                <div className='tokenName'>{item.tokenName}</div>
                <div 
                  className='percent' 
                  style={{ backgroundColor: getBackgroundColor(item.priceChange) }}>
                  {item.priceChange?.toFixed(0)}%
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
      <div className='logoback'>
        <img src={logo} alt="Logo" className="carousel-logo" /> 
      </div>
    </div>
  );
}

export default Trending;
