import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './home.css';

const SignupModal = ({ onClose }) => {
  const navigate = useNavigate(); // Use the navigate hook

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={onClose}>&times;</span>
        <h2>Join Us!</h2>
        <p>You need to sign up or log in to access this among other features.</p>
        {/* Add button that redirects to /login */}
        <button onClick={() => navigate('/login')} className="modal-button">Sign Up</button>
      </div>
    </div>
  );
}

export default SignupModal;
