import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './authContext';
import { FaRegCopy, FaTelegram } from 'react-icons/fa';
import './login.css'; // Import necessary styles

function LoginSignup() {
  const { setIsAuthenticated, setUserId } = useContext(AuthContext); // Destructure setUserId here
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [userName2, setUserName2] = useState('');
  const [password2, setPassword2] = useState('');
  const [transactionHash, setTransactionHash] = useState('');
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [signupErrorMessage, setSignupErrorMessage] = useState('');
  const [signupSuccessMessage, setSignupSuccessMessage] = useState('');
  const [copySuccess, setCopySuccess] = useState('');
  const navigate = useNavigate();
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:5000' : 'https://trendscout-7b4d161a92d4.herokuapp.com';

  const paymentAddress = "0x64109bc599114C506C8F01D3B64B677C6c69eDD7"; // Payment address

  const sendTelegramMessage = async (message) => {
    try {
      const telegramBotToken = '7449426366:AAHFTKceOJ2bU0U6PF4JdIZLysnhNWZiUv8';// Replace with your Telegram bot token
      const chatId = '-1001923745202';  // Your group chat ID

      await axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
        chat_id: chatId,
        text: message,
      });
    } catch (error) {
      console.error('Failed to send message to Telegram:', error);
    }
  };

  // Notify when component is visited
  useEffect(() => {
    sendTelegramMessage(`Someone just visited the Login/Signup page.`);
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();

    sendTelegramMessage(`Login attempt by username: ${userName}`);
  
    try {
      const response = await axios.post(`${baseUrl}/login`, {
        userName,
        password
      });
  
      if (response.status === 200) {
        const userId = response.data._id; // Get user ID from response
        sendTelegramMessage(`Login successful by username: ${userName}`);
        setIsAuthenticated(true);
        setUserId(userId); // Store user ID in context
        localStorage.setItem('userId', userId); 
        navigate('/');
      } else {
        setLoginErrorMessage('Invalid username or password');
      }
    } catch (error) {
      console.error('Login failed:', error);
      if (error.response && error.response.status === 403) {
        setLoginErrorMessage('Your subscription has expired. Please renew to continue using the service.');
      } else {
        setLoginErrorMessage('Invalid username or password');
      }
    }
};

  
  const handleSignup = async (event) => {
    event.preventDefault();

    sendTelegramMessage(`Signup attempt by username: ${userName2}`);

  
    if (!transactionHash.startsWith('0x') || transactionHash.length !== 66) {
      setSignupErrorMessage('Transaction hash must start with 0x and be 66 characters long. Please provide a valid transaction hash.');
      return;
    }
  
    setSignupErrorMessage('');
    setSignupSuccessMessage('');
  
    try {
      const response = await fetch(`${baseUrl}/api/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userName2,
          password2,
          transactionHash,
        }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        setSignupErrorMessage(data.error || 'An error occurred. Please try again.');
      } else {
        setSignupSuccessMessage(data.message);
        setUserName2('');
        setPassword2('');
        setTransactionHash('');
  
        setIsAuthenticated(true);
        navigate('/');
      }
    } catch (error) {
      console.error('Error during signup:', error);
      setSignupErrorMessage('An error occurred during signup. Please try again.');
    }
  };
  

  // Function to copy payment address
  const handleCopyAddress = () => {
    navigator.clipboard.writeText(paymentAddress)
      .then(() => {
        setCopySuccess('Payment address copied!');
        setTimeout(() => {
          setCopySuccess('');
        }, 1500); // Clear message after 1.5 seconds
      })
      .catch((err) => {
        setCopySuccess('Failed to copy!');
        setTimeout(() => {
          setCopySuccess('');
        }, 1500); // Clear message after 1.5 seconds
        console.error('Error copying address:', err);
      });
  };

  return (
    <div className="login-signup-container">
      <div className="loginmaintop">
      <div className="loginmainsocials">
        <div className="loginmain">
          <h3>Login</h3>
          <form onSubmit={handleLogin}>
            <div>
              <label>User Name</label>
              <input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Login</button>
            {/* Display login-specific error message */}
            {loginErrorMessage && <p className="error-message">{loginErrorMessage}</p>}
          </form>
        </div>
        <div className='telegrambox'>
  Support
  <a href="https://t.me/Gunari_Trends" target="_blank" rel="noopener noreferrer">
    <FaTelegram className='tgicon' />
  </a>
</div>

        </div>

        <div className="loginmain">
          <h3>Sign Up</h3>
          <form onSubmit={handleSignup}>
            <div>
              <label>User Name</label>
              <input
                type="text"
                value={userName2}
                onChange={(e) => setUserName2(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Password</label>
              <input
                type="password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Transaction Hash</label>
              <input
                type="text"
                value={transactionHash}
                onChange={(e) => setTransactionHash(e.target.value)}
                required
              />
            </div>
            <button type="submit">Sign Up</button>
            {/* Display signup-specific error and success messages */}
            {signupErrorMessage && <p className="error-message">{signupErrorMessage}</p>}
            {signupSuccessMessage && <p className="success-message">{signupSuccessMessage}</p>}
          </form>
        </div>

        <div className="signupinstructionmain">
          <h3>Sign Up Instructions</h3>
          <div className='instructions1'>
            <div>1. <strong className='strong'>Transfer 0.02 ETH</strong> to the payment address provided below.</div>
            <div>2. Enter the <strong className='strong'>transaction hash</strong> in the sign-up form.</div>
            <div>3. Create your <strong className='strong'>username</strong> and <strong className='strong'>password</strong>.</div>
            <div>4. Receive access for 30 days.</div>
            <p><strong className='strong'>Note:</strong> The subscription term begins as soon as the Ethereum transaction is confirmed.</p>
            <p>To renew after 30 days, repeat the steps above for an additional 30 days of access.</p>
          </div>
          <div className="tracked-tokens">
            <div className='accessList'>✔️ All tracked tokens</div>
            <div className='accessList'>✔️ Statistics and analytics</div>
            <div className='accessList'>✔️ Fun Features</div>
          </div>
          <div className='paymentaddressbox'>
            <div className='instructionsaddress2'>Payment Address</div>
            <div className='instructionsaddress'>
              {paymentAddress}
              <div className='copy'>
                <button onClick={handleCopyAddress} className="copy-button">
                  <FaRegCopy size={20} /> {/* Copy icon from react-icons */}
                </button>
                {copySuccess && <p className="copy-success">{copySuccess}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginSignup;
